.fa {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

td,
th {
    padding: 0;
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-family: "proxima-nova", Arial, sans-serif;
    font-size: 16px;
    color: #383838;
    line-height: 1.5;
    background-color: #fff;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a,
a:visited {
    text-decoration: underline;
    color: #006BB7;
    -webkit-transition: background-color 300ms ease-out 0s;
    -moz-transition: background-color 300ms ease-out 0s;
    -o-transition: background-color 300ms ease-out 0s;
    transition: background-color 300ms ease-out 0s;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #002D56;
}

a:hover,
a:focus {
    text-decoration: none;
}

*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
   box-sizing: border-box;
}

*:before,
*:after {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

img {
    vertical-align: middle;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #ddd;
}

p,
pre {
    font-family: inherit;
    font-size: 1em;
    font-weight: normal;
    color: #383838;
    margin: 0px 0px 20px 0px;
    font-style: normal;
}

a,
a:visited {
    text-decoration: underline;
    color: #006BB7;
    -webkit-transition: background-color 300ms ease-out 0s;
    -moz-transition: background-color 300ms ease-out 0s;
    -o-transition: background-color 300ms ease-out 0s;
    transition: background-color 300ms ease-out 0s;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 10px;
}

ul ul,
ul ol,
ol ul,
ol ol {
    margin-bottom: 0;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container:before,
.container:after {
    content: " ";
    display: table;
}

.container:after {
    clear: both;
}

@media (max-width: 1139) {
    .container {
        width: inherit;
    }
}

@media (min-width: 1140px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-fluid:before,
.container-fluid:after {
    content: " ";
    display: table;
}

.container-fluid:after {
    clear: both;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.row:before,
.row:after {
    content: " ";
    display: table;
}

.row:after {
    clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    float: left;
}

.col-xs-1 {
    width: 8.3333333333%;
}

.col-xs-2 {
    width: 16.6666666667%;
}

.col-xs-3 {
    width: 25%;
}

.col-xs-4 {
    width: 33.3333333333%;
}

.col-xs-5 {
    width: 41.6666666667%;
}

.col-xs-6 {
    width: 50%;
}

.col-xs-7 {
    width: 58.3333333333%;
}

.col-xs-8 {
    width: 66.6666666667%;
}

.col-xs-9 {
    width: 75%;
}

.col-xs-10 {
    width: 83.3333333333%;
}

.col-xs-11 {
    width: 91.6666666667%;
}

.col-xs-12 {
    width: 100%;
}

.col-xs-pull-0 {
    right: auto;
}

.col-xs-pull-1 {
    right: 8.3333333333%;
}

.col-xs-pull-2 {
    right: 16.6666666667%;
}

.col-xs-pull-3 {
    right: 25%;
}

.col-xs-pull-4 {
    right: 33.3333333333%;
}

.col-xs-pull-5 {
    right: 41.6666666667%;
}

.col-xs-pull-6 {
    right: 50%;
}

.col-xs-pull-7 {
    right: 58.3333333333%;
}

.col-xs-pull-8 {
    right: 66.6666666667%;
}

.col-xs-pull-9 {
    right: 75%;
}

.col-xs-pull-10 {
    right: 83.3333333333%;
}

.col-xs-pull-11 {
    right: 91.6666666667%;
}

.col-xs-pull-12 {
    right: 100%;
}

.col-xs-push-0 {
    left: auto;
}

.col-xs-push-1 {
    left: 8.3333333333%;
}

.col-xs-push-2 {
    left: 16.6666666667%;
}

.col-xs-push-3 {
    left: 25%;
}

.col-xs-push-4 {
    left: 33.3333333333%;
}

.col-xs-push-5 {
    left: 41.6666666667%;
}

.col-xs-push-6 {
    left: 50%;
}

.col-xs-push-7 {
    left: 58.3333333333%;
}

.col-xs-push-8 {
    left: 66.6666666667%;
}

.col-xs-push-9 {
    left: 75%;
}

.col-xs-push-10 {
    left: 83.3333333333%;
}

.col-xs-push-11 {
    left: 91.6666666667%;
}

.col-xs-push-12 {
    left: 100%;
}

.col-xs-offset-0 {
    margin-left: 0%;
}

.col-xs-offset-1 {
    margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
    margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
    margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
    margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
    margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
    margin-left: 100%;
}

@media (min-width: 768px) {

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }

    .col-sm-1 {
        width: 8.3333333333%;
    }

    .col-sm-2 {
        width: 16.6666666667%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-4 {
        width: 33.3333333333%;
    }

    .col-sm-5 {
        width: 41.6666666667%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-7 {
        width: 58.3333333333%;
    }

    .col-sm-8 {
        width: 66.6666666667%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-10 {
        width: 83.3333333333%;
    }

    .col-sm-11 {
        width: 91.6666666667%;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-pull-0 {
        right: auto;
    }

    .col-sm-pull-1 {
        right: 8.3333333333%;
    }

    .col-sm-pull-2 {
        right: 16.6666666667%;
    }

    .col-sm-pull-3 {
        right: 25%;
    }

    .col-sm-pull-4 {
        right: 33.3333333333%;
    }

    .col-sm-pull-5 {
        right: 41.6666666667%;
    }

    .col-sm-pull-6 {
        right: 50%;
    }

    .col-sm-pull-7 {
        right: 58.3333333333%;
    }

    .col-sm-pull-8 {
        right: 66.6666666667%;
    }

    .col-sm-pull-9 {
        right: 75%;
    }

    .col-sm-pull-10 {
        right: 83.3333333333%;
    }

    .col-sm-pull-11 {
        right: 91.6666666667%;
    }

    .col-sm-pull-12 {
        right: 100%;
    }

    .col-sm-push-0 {
        left: auto;
    }

    .col-sm-push-1 {
        left: 8.3333333333%;
    }

    .col-sm-push-2 {
        left: 16.6666666667%;
    }

    .col-sm-push-3 {
        left: 25%;
    }

    .col-sm-push-4 {
        left: 33.3333333333%;
    }

    .col-sm-push-5 {
        left: 41.6666666667%;
    }

    .col-sm-push-6 {
        left: 50%;
    }

    .col-sm-push-7 {
        left: 58.3333333333%;
    }

    .col-sm-push-8 {
        left: 66.6666666667%;
    }

    .col-sm-push-9 {
        left: 75%;
    }

    .col-sm-push-10 {
        left: 83.3333333333%;
    }

    .col-sm-push-11 {
        left: 91.6666666667%;
    }

    .col-sm-push-12 {
        left: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0%;
    }

    .col-sm-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-sm-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 992px) {

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }

    .col-md-1 {
        width: 8.3333333333%;
    }

    .col-md-2 {
        width: 16.6666666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.3333333333%;
    }

    .col-md-5 {
        width: 41.6666666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.3333333333%;
    }

    .col-md-8 {
        width: 66.6666666667%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.3333333333%;
    }

    .col-md-11 {
        width: 91.6666666667%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-pull-0 {
        right: auto;
    }

    .col-md-pull-1 {
        right: 8.3333333333%;
    }

    .col-md-pull-2 {
        right: 16.6666666667%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-4 {
        right: 33.3333333333%;
    }

    .col-md-pull-5 {
        right: 41.6666666667%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-7 {
        right: 58.3333333333%;
    }

    .col-md-pull-8 {
        right: 66.6666666667%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-10 {
        right: 83.3333333333%;
    }

    .col-md-pull-11 {
        right: 91.6666666667%;
    }

    .col-md-pull-12 {
        right: 100%;
    }

    .col-md-push-0 {
        left: auto;
    }

    .col-md-push-1 {
        left: 8.3333333333%;
    }

    .col-md-push-2 {
        left: 16.6666666667%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-4 {
        left: 33.3333333333%;
    }

    .col-md-push-5 {
        left: 41.6666666667%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-7 {
        left: 58.3333333333%;
    }

    .col-md-push-8 {
        left: 66.6666666667%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-10 {
        left: 83.3333333333%;
    }

    .col-md-push-11 {
        left: 91.6666666667%;
    }

    .col-md-push-12 {
        left: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0%;
    }

    .col-md-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-md-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-md-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-md-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 800px) {

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        float: left;
    }

    .col-lg-1 {
        width: 8.3333333333%;
    }

    .col-lg-2 {
        width: 16.6666666667%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-4 {
        width: 33.3333333333%;
    }

    .col-lg-5 {
        width: 41.6666666667%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-7 {
        width: 58.3333333333%;
    }

    .col-lg-8 {
        width: 66.6666666667%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-10 {
        width: 83.3333333333%;
    }

    .col-lg-11 {
        width: 91.6666666667%;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-pull-0 {
        right: auto;
    }

    .col-lg-pull-1 {
        right: 8.3333333333%;
    }

    .col-lg-pull-2 {
        right: 16.6666666667%;
    }

    .col-lg-pull-3 {
        right: 25%;
    }

    .col-lg-pull-4 {
        right: 33.3333333333%;
    }

    .col-lg-pull-5 {
        right: 41.6666666667%;
    }

    .col-lg-pull-6 {
        right: 50%;
    }

    .col-lg-pull-7 {
        right: 58.3333333333%;
    }

    .col-lg-pull-8 {
        right: 66.6666666667%;
    }

    .col-lg-pull-9 {
        right: 75%;
    }

    .col-lg-pull-10 {
        right: 83.3333333333%;
    }

    .col-lg-pull-11 {
        right: 91.6666666667%;
    }

    .col-lg-pull-12 {
        right: 100%;
    }

    .col-lg-push-0 {
        left: auto;
    }

    .col-lg-push-1 {
        left: 8.3333333333%;
    }

    .col-lg-push-2 {
        left: 16.6666666667%;
    }

    .col-lg-push-3 {
        left: 25%;
    }

    .col-lg-push-4 {
        left: 33.3333333333%;
    }

    .col-lg-push-5 {
        left: 41.6666666667%;
    }

    .col-lg-push-6 {
        left: 50%;
    }

    .col-lg-push-7 {
        left: 58.3333333333%;
    }

    .col-lg-push-8 {
        left: 66.6666666667%;
    }

    .col-lg-push-9 {
        left: 75%;
    }

    .col-lg-push-10 {
        left: 83.3333333333%;
    }

    .col-lg-push-11 {
        left: 91.6666666667%;
    }

    .col-lg-push-12 {
        left: 100%;
    }

    .col-lg-offset-0 {
        margin-left: 0%;
    }

    .col-lg-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-lg-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-lg-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-lg-offset-12 {
        margin-left: 100%;
    }
}

table {
    background-color: transparent;
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #a5a6a7;
    text-align: left;
}

th {
    text-align: left;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table>thead>tr>th,
.table>thead>tr>td,
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.table>caption+thead>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>th,
.table>thead:first-child>tr:first-child>td {
    border-top: 0;
}

.table>tbody+tbody {
    border-top: 2px solid #ddd;
}

.table .table {
    background-color: #fff;
}

.table-condensed>thead>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>tfoot>tr>td {
    padding: 5px;
}

.table-bordered {
    border: 1px solid #ddd;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>tfoot>tr>td {
    border: 1px solid #ddd;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    border-bottom-width: 2px;
}

.table-striped>tbody>tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.table-hover>tbody>tr:hover {
    background-color: #f5f5f5;
}

table col[class*="col-"] {
    position: static;
    float: none;
    display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell;
}

.table>thead>tr>td.active,
.table>thead>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th {
    background-color: #f5f5f5;
}

.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover,
.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr.active:hover>th {
    background-color: #e8e8e8;
}

.table>thead>tr>td.success,
.table>thead>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th {
    background-color: #ebf4d5;
}

.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover,
.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr.success:hover>th {
    background-color: #e1efc1;
}

.table>thead>tr>td.info,
.table>thead>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th {
    background-color: #b0ddff;
}

.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover,
.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr.info:hover>th {
    background-color: #96d2ff;
}

.table>thead>tr>td.warning,
.table>thead>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th {
    background-color: #fbd7ae;
}

.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover,
.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr.warning:hover>th {
    background-color: #f9cb96;
}

.table>thead>tr>td.danger,
.table>thead>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th {
    background-color: #fef5f5;
}

.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover,
.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr.danger:hover>th {
    background-color: #fbdedf;
}

.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }

    .table-responsive>.table {
        margin-bottom: 0;
    }

    .table-responsive>.table>thead>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>tfoot>tr>td {
        white-space: nowrap;
    }

    .table-responsive>.table-bordered {
        border: 0;
    }

    .table-responsive>.table-bordered>thead>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child {
        border-left: 0;
    }

    .table-responsive>.table-bordered>thead>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child {
        border-right: 0;
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td {
        border-bottom: 0;
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #58595B;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="search"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type="file"] {
    display: block;
}

input[type="range"] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

output {
    display: block;
    padding-top: 4px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #8b8c8e;
}

input[type="radio"][disabled],
input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;
}

@-ms-viewport {
    width: device-width;
}

h3.footer-title {
    border-bottom: 0px none transparent;
    color: #fff;
    font-size: 1.5em;
    font-weight: 600;
    font-style: normal;
    margin: 35px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}

@media(max-width:1023px) {
    h3.footer-title {
        font-size: 1.25em;
    }
}

ul.footer-links {
    margin: 0 0 35px;
    list-style: none outside none;
    padding: 0;
}

ul.footer-links a:hover,
div.footer-secondary-links>div:first-child a:hover {
    text-decoration: underline;
}

html {
    height: 100%;
    -ms-overflow-style: scrollbar;
}

body:before {
    content: " ";
    display: block;
    height: 72px;
    visibility: hidden;
}

@media (min-width: 800px) {
    body:before {
        height: 120px;
    }
}

html,
body {
    height: 100%;
}

h4 {
    font-weight: bold;
}

hr.fc-divider {
    height: 0;
    margin: 0;
    padding: 0 0 2px;
    border-width: 1px 0;
}

.skip-navigation {
    position: absolute;
    top: -10000px;
    left: -10000px;
    z-index: 999;
}

.skip-navigation:focus {
    left: 999px;
}

div.textbox {
    border: none;
    width: 860px;
    height: 525px;
}

.text-right {
    text-align: right;
}