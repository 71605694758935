.info-list {
    border-style: none;
    width: 70%;
    margin-bottom: 50px;

    .info-item-label {
        font-weight: bold !important;
        color: #0f5596 !important;
    }

    .info-list-header {
        display: block;
        font-size: 30px;
        color: #162D54;
        line-height: 1;
        margin: 0 0 25px;
    }

    .k-listview-content {
        display: block;

        .k-listview-item {
            border-bottom-color: #707070;
            line-height: 1;
            padding: 15px 0;
            font-size: 20px;


            &.sub-item {
                padding-left: 48px;
            }

            .info-item-label {
                color: #58595B;
                font-weight: 400;
                text-align: left;
            }

            &.red-color {
                .info-item-label {
                    color: #C4171B;
                }

                .info-item-value {
                    color: #C4171B;
                }
            }

            .info-item-value {
                color: #58595B;
                font-weight: 400;
                text-align: right;
            }
        }
    }

    .k-listview-item {
        border-bottom: 1px solid lightgray;
        display: flex;
        justify-content: space-between;
    }

    .k-listview-header {
        border: 0;
    }
}

.p-margin-l{
    margin: 0px 10px 0px 0px !important;
}
.p-margin{
    margin: 0px 0px 0px 0px !important;
}
