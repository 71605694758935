@import "app/shared/components/info-list/info-list.component.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

.section-container {
    padding: 50px 35px 50px 35px;
    border-left: solid 1px #707070; // TODO: this doesn't look good on mobile
    margin-top: 0px; // TODO: this seems like not best way
    height: 100%;
}

.btn-icon-only {
    box-shadow: none;

    .k-icon {
        font-size: 30px;
        color: #0f5596;
    }
}

.k-button {
    border-radius: unset;
}

.capitalize-button {
    text-transform: capitalize;
}

/*
.k-button-solid-primary, .k-button-solid-base {
    &:not(:disabled, .k-input-button) {
        background-color: #0f5597;

        &:not(.appbar-button) {
            border-color: #0f5597;
        }

        color: white;
    }

    border-radius: unset;
}*/

.k-input-solid,
.k-picker-solid {
    border-radius: unset;
}

.logo img {
    margin-top: 10px;
}

.k-table-td,
.k-table-th {
    padding: 4px 8px !important;
}
.k-filter-row {
    .k-input,
    .k-picker {
        padding: 8px 0px;
        background-color: transparent;
        height: 40px;
    }
    .k-input-inner {
        padding: 0px;
    }
}

h1 {
    color: #0b365f;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 5px;
}

h2 {
    color: #0f5597;
    font-size: 40px;
    font-weight: 700;
}

h3 {
    color: #063461;
}

.denver-content-area-master {
    min-height: 100%;
    height: auto !important;
    z-index: 9999999999999;
    clear: both;
}

.m-date span.k-picker-wrap {
    border-color: #eeeeee !important;
}

.finance-report-table span.k-dropdown-wrap {
    border: none;
    color: #0f5597 !important;
    width: 50%;
}

.finance-report-table thead {
    background-color: #eee !important;
}

.finance-report-table .k-icon {
    color: #0f5597 !important;
}
@media (max-width: 800px) {
    body:before {
        height: 80px !important;
    }
}
body:before {
    height: auto !important;
}

.k-button.k-primary {
    background: #0f5597;
    color: #ffffff;
}

.material-icons.orange {
    color: orange;
}

.material-icons.blue {
    color: #0f5597;
}

.k-button-solid-primary {
    background: #0f5597;
}

.skip-navigation {
    position: absolute;
    top: -10000px;
    left: -10000px;
    z-index: 999;
}

.skip-navigation:focus {
    left: 999px;
}

.cursor-pointer { cursor: pointer; }